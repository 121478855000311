/* eslint-disable no-use-before-define */
/* eslint-disable no-unreachable */
import React, { useContext, useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
// import Tooltip from 'antd/es/tooltip';
import { PauseCircleOutlined, PlayCircleOutlined, LoadingOutlined, StopOutlined } from '@ant-design/icons';
// import { BiStopCircle } from "react-icons-ng/bi"
import { MainContext } from 'src/Context';
import { Avatar, Modal } from 'antd';

import { colorCircleNoSize, statusCircle } from "src/layouts/widgets/CustomLayout/index"
import { dateTimeFormatter } from "src/utils/functions/formater"
import Tooltip from 'src/layouts/widgets/Companies/i4c_V2/components/Generic/Tooltip';
// import FloatContent from 'src/layouts/widgets/Core/FloatContent';
import UserAddOutlined from '@ant-design/icons/UserAddOutlined';

const style1 = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "100%",
  fontWeight: "bold"
}

const style2 = {
  overflow: "hidden",
  fontWeight: "normal",
  paddingLeft: 5,
  // padding: "10px 10px 10px 10px"
}

const style3 = {
  float: "inline-end"
}

const styleIcons = {
  // float: "inline-end",
  cursor: "pointer"
}


const sumValues = (e, property) => {
  return e.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);
}

const sliceText = (el, e) => {
  const type = el?.type
  const textLength = el?.textLength ? el?.textLength : false
  if (type === "int") {
    return (textLength && e && (e.length > textLength)) ? ("..." + e.slice(-textLength)) : e
  } else if (type === "string") {
    return (textLength && e && (e.length > textLength)) ? ("..." + e.slice(-textLength)) : e
  } else if (type === "date") {
    return dateTimeFormatter("date", Number(e), "dd.mm.yyyy hh:mm")
  } else if (type === "duration") {
    return dateTimeFormatter("duration", Number(e), "dd:hh:mm")
  } else if (type === "array") {
    const valueType = el?.valueType
    const property = el?.property
    switch (valueType) {
      case "SUM": return sumValues(e, property);
      // case "SUM": return sumValues();
      // case "SUM": return sumValues();
      // case "SUM": return sumValues();
      default: return JSON.stringify(e);
    }
    return JSON.stringify(e)
  }
}

const renderValues = (e) => {
  switch (e) {
    case "notReady": return <>{" "}{statusCircle("#ff0000", 13)}</>;
    case "ready": return <>{" "}{statusCircle("#0b6f02", 13)}</>;
    case "inPreparation": return <>{" "}{statusCircle("#50c4fa", 13)}</>;
    default: return ""
  }
}

const handleObjects = (el, lang) => {
  if (el) {
    if (typeof el === 'object') {
      const key = Object.keys(el)
      if (Array.isArray(key) && key.length > 0) {
        const k = key[0]
        const string = el?.[k]
        return string
      } else {
        const string = el ? el : ""
        return string
      }
    } else {
      if (typeof el === 'string') {
        return <>{lang?.[el] ?? el} {renderValues(el)}</>
      } else {
        return "no string"
      }
      // console.log(el?.value)
    }
  } else {
    return ""
    // console.log("none")
  }
}


const iconRe = (_e) => {
  // console.log(_e)
  const e = _e?.labelUser?.split(" ")
  const f = e?.[0]?.slice(0, 1).toUpperCase()
  const l = e?.[1]?.slice(0, 1).toUpperCase()
  const n = f + l
  // <UserOutlined />
  // console.log(_e)
  if (e && n) {
    return (
      <span style={{ color: "#fff" }}>
        {n}
      </span>
    )
  }
}
const renderWorkers = (_e, usersOnAsset) => {
  // console.log(_e)
  const e = _e.filter((f) => (f?.active !== 0));
  // console.log(e)
  return (
    <Avatar.Group>
      {e.map((item, i) => {
        // console.log(item?.userId)
        const b = usersOnAsset.find((f) => f?.userId === item?.userId)
        const backgroundColor = b?.eventStartTimestamp ? (b?.code === "UI001" && b?.subCode === "003" ? "#FF7518" : "#ff0000") : (b?.operationId ? "#87d068" : "#808080")
        return <Avatar title={item?.labelUser} style={{ backgroundColor }} gap={1} icon={iconRe(item)} />
      })}
    </Avatar.Group>
  )
}

const InfoCardOp = ({ board, item, products, cardInfo, statuses, onClickCardBody, onClickCardHeaderPause, onPause, usersOnAsset }) => {
  // console.log(item)
  const context = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false)
  const { lang } = context.state
  const findProduct = products?.find((f) => f._id === item?.param?.productId)

  // console.log(findProduct)
  const header = {
    // label: findProduct?.deliveryStatus?.label,
    icon: colorCircleNoSize(findProduct?.deliveryStatus?.label),
    // label: colorCircleNoSize(findProduct?.deliveryStatus?.label),
    tooltip: findProduct?.deliveryStatus?.label,
    value: findProduct?.label,
    // tooltipValue: "XKAŠIRANJE LICE"
  }

  const handlePause = (currentStatus, statusDef, onPause) => {
    const s = statusDef.find((f) => f === currentStatus)
    if (s) {
      return onPause ? <PauseCircleOutlined title={lang?.["resumePlay"] ?? "resumePlay"} style={{ ...styleIcons, color: (_onPause ? ("#fff") : "") }} /> : <StopOutlined title={lang?.["setPause"] ?? "setPause"} style={{ ...styleIcons }} />
    }
  }

  // useEffect(() => {
  //
  // }, [])

  const addUserOnOp = async () => {
    const { confirm } = Modal;
    const n = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/NoIcon")
    const NoIcon = n?.default
    const co = await import("src/layouts/widgets/Companies/i4c_V2/components/OpV2/Coworkers")
    const Coworkers = co?.default
    // console.log(item)
    confirm({
      icon: <NoIcon />,
      width: "90%",
      closable: true,
      style: { minWidth: 1000 },
      content: <Coworkers
        context={context}
        board={board}
        item={item}
      />,
      cancelButtonProps: { type: "primary", danger: true, ghost: false },
      cancelButtonProps: { style: { display: 'none', marginTop: 0 } },
      okButtonProps: { style: { display: 'none', marginTop: 0 } },
      // content: <Button onClick={destroyAll}>Click to destroy all</Button>,
      onOk() { },
      onCancel() {
        console.log('Cancel');
      }
    })
  }

  const placement = "right"
  const currentStatus = item?.param?.status?._id
  const _onPause = item?.param?.onPause
  const PartiallyFinished = item?.param?.PartiallyFinished
  const statusDef = [statuses?.startPreperationId, statuses?.startProductionId]
  const p = item?.param
  const workers = (item?.param?.workers && item?.param?.workers.length > 0) ? item?.param?.workers.filter((f) => f?.active) : []

  // console.log(workers)
  // console.log(p)
  const users = {
    "textLength": 40,
    "tooltipValue": "users",
    "type": "string",
    "value": "users"
  }
  const _cardInfo = [...cardInfo, users]
  return (
    <Card>
      <Card.Header style={{ borderRadius: 0, background: (_onPause ? ("#fb4545") : "") }}>
        <div style={style1} title={header?.tooltip}>
          {header?.icon} {PartiallyFinished ? <b title={lang?.["PartiallyFinished"] ?? "PartiallyFinished"} style={{ color: "red" }} >P</b> : null}
          <span style={{ ...style2, color: (_onPause ? ("#fff") : "") }}>
            {header?.value}
          </span>
          <UserAddOutlined style={{ float: "right" }} onClick={addUserOnOp} />
          {/*
          <span style={style3} onClick={() => {
              onClickCardHeaderPause(item)
              setIsLoading(true)
            }}>
            {isLoading ? <LoadingOutlined style={{ color: (_onPause ? ("#fff") : "") }} spin/> : handlePause(currentStatus, statusDef, _onPause)}
          </span>
          */}
        </div>
      </Card.Header>
      <Card.Body style={{ position: "relative", padding: "10px 10px 10px 10px" }} onClick={onClickCardBody}>
        <div title={lang?.["operationName"] ?? "operationName"} style={style2}>
          <b>{lang?.["operationNameShort"] ?? "operationNameShort"}</b>: {p?.operation?.operationName}
        </div>
        <div title={lang?.["productName"] ?? "productName"} style={style2}>
          <b>{lang?.["shortProductName"] ?? "shortProductName"}</b>: {p?.productName}
        </div>
        <div title={lang?.["KDN"] ?? "Koda DN"} style={style2}>
          <b>{lang?.["KDN"] ?? "KDN"}</b>: {p?.woOrderNumber}
        </div>
        <div title={lang?.["projectCode"] ?? "projectCode"} style={style2}>
          <b>{lang?.["projectCode"] ?? "projectCode"}</b>: {p?.projectCode}
        </div>
        <div title={lang?.["startDate"] ?? "startDate"} style={style2}>
          <b>{lang?.["startDate"] ?? "startDate"}</b>: {dateTimeFormatter("date", Number(p?.startDate), "dd.mm.yyyy hh:mm")}
        </div>
        <div title={lang?.["projectManager"] ?? "projectManager"} style={style2}>
          <b>{lang?.["projectManagerShort"] ?? "projectManagerShort"}</b>: {p?.projectManager}
        </div>
        <div title={lang?.["workers"] ?? "workers"} style={style2}>
          <b>{lang?.["DE"] ?? "DE"}</b>: {(p?.workers && p?.workers.length > 0) ? renderWorkers(p?.workers, usersOnAsset) : []}
        </div>
      </Card.Body>
    </Card>
  )
}

export default InfoCardOp;

// {_cardInfo.map((el, i) => {
//   // const p = item?.param
//   // console.log(p)
//   p.operationNameShort = p?.operation?.operationName
//   p.shortProductName = item?.param?.productName
//   p.operationName = item?.param?.operation?.operationName
//   p.previousOperationStatus = item?.param?.prevOperation?.operationName
//   p.shortPlannedSetupStartDate = item?.param?.plannedSetupStartDate
//   p.PO = item?.param?.erp_OrderNo
//   p._id = item?._id
//   // p.users = renderWorkers(workers)
//   // p.users = <FloatContent />
//   // console.log(item?.param?.note)
//   // p.PO = item?.param?.erp_OrderNo + "-" + item?.param?.operation?.erpId
//   const value = (typeof el?.value === 'string') ? el?.value : "null"
//   const _value = p?.[value]
//   const tooltipValue = p?.[tooltipValue]
//   const _p = sliceText(el, _value)
//   // console.log(_value)
//   return (
//     <div key={JSON.stringify(i)} style={style1} onClick={onClickCardBody}>
//       <Tooltip placement={placement} text={lang?.[el?.tooltipValue] ?? el?.tooltipValue}>
//         {lang?.[el?.value] ?? el?.value}:
//       </Tooltip>
//       {_value &&
//         <span style={style2} title={lang?.[_value] ?? _value}>
//           {handleObjects(_p, lang, el)}
//         </span>
//       }
//     </div>
//   )
// })}
