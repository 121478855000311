import React from 'react';
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import { MainContext } from 'src/Context';
import { Table } from 'antd';
import { allinOne, sortingAsc, removeDuplicate, removeDuplicateObj, msg } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { Input, Select } from 'antd';


class StanjeProizvodnje extends React.Component {
    static contextType = MainContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            _tableData: [],
            projects: [],
            project: undefined,
            search: "",
            screenHeight: window.innerHeight
        };
    }

    componentDidMount() {
        this._isMounted = true;
        // console.log(this.props)
        window.addEventListener('resize', this.handleResize)
        this.callAll()
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.handleResize); 
    }

    componentDidUpdate() {
        const { contextChangeTrue } = this?.context?.state;
        if (contextChangeTrue) {
            this.callAll()
            this.context.callMe(false)
        }
    }
    callAll() {
        this.getOrders()
    }
    handleResize = () => {
        this.setState({ screenHeight: window.innerHeight })
      }

    getOrders = async () => {
        const { lang } = this.context.state;
        const result = await allinOne("getUsersOnOperation", {})
        const result1 = await allinOne("getSpecDocument", {})
        if (result) {
            const _asset = result1?.assetsArr
            const asset = removeDuplicateObj([...result, ..._asset], "userId")
            const res = asset.map((el, i) => ({ ...el, key: i + 1 }))
            const pr = result.map(el => ({ value: el?.businessOrder, label: el?.businessOrder }))
            const PR = sortingAsc(removeDuplicate(pr), ["label"])
            // console.log({
            //     pr,
            //     PR
            //   })
            this.setState({
                tableData: res,
                _tableData: res,
                projects: PR
            })
        } else {
            msg("warning", (lang?.["noUserDefined"] ?? "noUserDefined"), 2)
        }


    }

    handleChange = (e) => {
        const { _tableData, project } = this.state
        const td = (e && e.length > 0) ? _tableData?.filter(el => el?.labelUser?.toLowerCase().includes(e.toLowerCase())
            || el?.productName?.toLowerCase().includes(e.toLowerCase())
            || el?.workOrder?.toLowerCase().includes(e.toLowerCase())
            || el?.businessOrder?.toLowerCase().includes(e.toLowerCase())
        )
            : _tableData
        if (project) {
            const op = td.filter(f => f?.businessOrder === project)
            const _op = op.map((el, i) => ({ ...el, key: i + 1 }))
            this.setState({
                tableData: _op,
                search: e
            });
        } else {
            this.setState({
                tableData: td,
                search: e
            });
        }
    }
    onChange = (value, selected) => {
        const { _tableData, search } = this.state
        const td = (search && search.length > 0) ? _tableData?.filter(el => el?.labelUser?.toLowerCase().includes(search.toLowerCase())
            || el?.productName?.toLowerCase().includes(search.toLowerCase())
            || el?.workOrder?.toLowerCase().includes(search.toLowerCase())
            || el?.businessOrder?.toLowerCase().includes(search.toLowerCase())
        )
            : _tableData
        if (value) {
            const op = td.filter(f => f?.businessOrder === selected?.value)
            const _op = op.map((el, i) => ({ ...el, key: i + 1 }))
            this.setState({
                tableData: _op,
                project: value
            });
        } else {
            this.setState({
                tableData: td,
                project: value
            });
        }

        // console.log(e)
    }

    render() {
        const { lang } = this.context.state;
        const columns = [
            {
                title: '#',
                dataIndex: 'key',
                key: 'key',
                width: 35,
                render: (text) => <span>{text}</span>,
            },
            {
                title: lang["name"] ? lang["name"] : "name",
                dataIndex: 'name',
                key: 'key',
                //width: 250,
                render: (text, record) => <span>{record?.labelUser}</span>,
            },
            {
                title: lang["BusinessOrder"] ? lang["BusinessOrder"] : "Project",
                dataIndex: 'businessOrder',
                key: 'key',
                /// width: 100,
                render: (text, record) => <span>{record?.businessOrder ?? "/"}</span>,
            },
            {
                title: lang["WorkOrder"] ? lang["WorkOrder"] : "WorkOrder",
                dataIndex: 'workOrder',
                key: 'key',
                /// width: 100,
                render: (text, record) => <span>{record?.workOrder ?? "/"}</span>,
            },
            {
                title: lang["productName"] ? lang["productName"] : "productName",
                dataIndex: 'productName',
                key: 'key',
                //width: 250,
                render: (text, record) => <span>{record?.productName ?? "/"}</span>,
            },
            {
                title: lang["productionTime"] ? lang["productionTime"] : "productionTime",
                dataIndex: 'productionTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.operationKpis?.productionTime?.duration ? dateTimeFormatter("duration", record?.operationKpis?.productionTime?.duration, "h") : "/"}</span>,
            },
            {
                title: lang["setupTime"] ? lang["setupTime"] : "setupTime",
                dataIndex: 'setupTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.operationKpis?.setupTime?.duration ? dateTimeFormatter("duration", record?.operationKpis?.setupTime?.duration, "h") : "/"}</span>,
            },
            {
                title: lang["waitTime"] ? lang["waitTime"] : "waitTime",
                dataIndex: 'waitTime',
                key: 'key',
                width: 180,
                render: (text, record) => <span>{record?.operationKpis?.waitTime?.duration ? dateTimeFormatter("duration", record?.operationKpis?.waitTime?.duration, "h") : "/"}</span>,
            },

        ]
        const { tableData, projects,screenHeight } = this.state
        return (
            <Col md="12" style={{ paddingLeft: "0px"}}>
                <div>
                    <Input placeholder={lang?.["search"] ?? "search"} style={{ width: 205, marginRight: 5, marginBottom: 5 }} onChange={(e) => this.handleChange(e.target.value)} />
                    <Select
                        style={{
                            width: 300,
                        }}
                        showSearch
                        onChange={this.onChange}
                        //onSearch={onSearch}
                        allowClear
                        options={projects} />
                </div>
                <div style={{overflowY:"scroll", height:screenHeight-150}}>
                    <Table
                        className="table-striped-rows"
                        columns={columns}
                        dataSource={sortingAsc(tableData, ["labelUser"]).map((el, i) => ({ ...el, key: i + 1 }))}
                        pagination={{
                            position: ["bottomCenter"],
                            defaultPageSize: 20,
                            pageSizeOptions: ["15", "25", "30", "50", "75", "100"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" },
                        }}
                    />
                </div>
            </Col>
        )
    }
}
export default withRouter(StanjeProizvodnje);

// import React from 'react';
// import { withRouter } from "react-router-dom";
// import { MainContext } from 'src/Context';
// // import { Image } from 'antd';
// // import * as comp from 'antd';

// class StanjeProizvodnje extends React.Component {
//   static contextType = MainContext;
//   _isMounted = false;
//   constructor(props) {
//     super(props)
//     this.state = { ...this.props }
//   }

//   componentDidMount() {
//     this._isMounted = true;
//     this.getData()
//   }

//   getData = () => {
//     if (this._isMounted) {
//       this.setState({
//         title: "text"
//       })

//     }
//   }

//   componentWillUnmount() {
//     this._isMounted = false;
//     this.setState({ title: "" })
//   }

//   render() {
//     // console.log(this.state)
//     return (
//       <div>dihopgpfokjhpkfgjkh</div>
//     )
//   }
// }