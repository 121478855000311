/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
// import { FloatButton  } from 'antd';
import { Avatar, Tooltip, Modal } from 'antd';
// import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { MainContext } from 'src/Context';
import { allinOne } from "src/utils/functions/filterFunction"
import { content } from "src/utils/functions/core"
import { socket } from 'src/utils/socket';
// import { colorRender } from "src/layouts/widgets/CustomLayout/index"

const iconRe = (e) => {
  // console.log(e)
  const f = e?.firstName?.slice(0, 1).toUpperCase()
  const l = e?.lastName?.slice(0, 1).toUpperCase()
  const n = f + l
  // <UserOutlined />
  return (
    <span style={{ color: "#fff" }}>
      {n}
    </span>
  )
}

const App = () => {
  const { clientUrl, user } = content()
  const mounted = useRef(true);
  const location = useLocation()
  const context = useContext(MainContext);
  const assetId = context.state?.assetId ? context.state?.assetId : "12928e50-6a5e-4c21-b726-1ca4ef48c3cc"
  const [operators, setOperators] = useState(false)
  const [asset, setAsset] = useState(false)
  const [_operators, _setOperators] = useState(false)
  const [show, setShow] = useState(false)

  const getData = async () => {
    if (mounted.current) {
      const result = await allinOne("prepareUsersAvatars", { assetId })
      if (result?.success) {
        return result
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      const operators = d?.users
      const _asset = d?.asset
      setAsset(_asset)
      _setOperators(operators)
      setShow(true)
    }
  }
  const f1 = async () => {
    const d = await getData()
    if (d) {
      const operators = d?.users
      const _asset = d?.asset
      // console.log(_asset)
      //setAsset(_asset)
      _setOperators(operators)
      setShow(true)
    }
  }

  const s = async () => {
    socket.on('usersOn', (e) => {
      // console.log({ line: "usersOn", e });
      if (assetId) {
        const asset = e?.[assetId]
        setAsset(asset)
        setShow(true)
      }
    })
    socket.on('usersOnV2', (e) => {
      // console.log({ line: "usersOnV2", e });
      setTimeout(() => {
        mounted.current = true
        f()
      }, 2000)
    })
  }

  useEffect(() => {
    if (assetId) {
      mounted.current = true
      f()
    }
    s()
    return () => {
      mounted.current = false
    }
  }, [assetId])

  useEffect(() => {
    if (show) {
      const u = (_operators && _operators.length > 0) ? _operators.find((f) => f?.userId === user?.userId) : false
      if (u && asset) {
        const o = _operators.filter((f) => asset.find(fi => fi?.userId === f?.userId)?.userId === f?.userId)
        const mergedArray = asset.map(item1 => {
          const item2 = _operators.find(item => item.userId === item1.userId);
          return { ...item1, ...item2 };
        });
        setOperators(mergedArray)
        setShow(false)
      } else {
        setOperators([])
        setShow(false)
      }
    }
    return () => { mounted.current = false }
  }, [show])

  const onClickAvatar = async (e) => {
    const { confirm } = Modal;
    const n = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/NoIcon")
    const NoIcon = n?.default
    const sp = await import("src/layouts/widgets/Companies/i4c_V2/main/StanjeProizvodnjeFuncComp")
    const StanjeProizvodnjeFuncComp = sp?.default
    confirm({
      icon: <NoIcon />,
      width: "90%",
      closable: true,
      style: { minWidth: 1000 },
      content: <StanjeProizvodnjeFuncComp
        context={context}
        // board={board}
        user={e}
      />,
      cancelButtonProps: { type: "primary", danger: true, ghost: false },
      cancelButtonProps: { style: { display: 'none', marginTop: 0 } },
      okButtonProps: { style: { display: 'none', marginTop: 0 } },
      // content: <Button onClick={destroyAll}>Click to destroy all</Button>,
      onOk() { },
      onCancel() {
        console.log('Cancel');
      }
    })
  }

  return (
    <span style={{ marginLeft: 10 }}>
      {/*<Avatar.Group>*/}
      {operators && operators.map((el, i) => {
        const backgroundColor = el?.eventStartTimestamp ? (el?.code === "UI001" && el?.subCode === "003" ? "#FF7518" : "#ff0000") : (el?.operationId ? "#87d068" : "#808080")//colorRender(el?.labelUser?.slice(0, 1).toLowerCase())
        return (
          <Tooltip key={JSON.stringify(i)} title={el?.labelUser} placement="top">
            <Avatar
              style={{
                backgroundColor,
                cursor: "pointer"
              }}
              //key={JSON.stringify(backgroundColor+i)}
              gap={1}
              // src={clientUrl + "/media/users/" + el?.img}
              icon={iconRe(el)}
              onClick={() => onClickAvatar(el)}
            />
          </Tooltip>
        )
      })}
      {/*</Avatar.Group>*/}
    </span>
  )
}
export default App;
