/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { message } from 'antd';
// import * as icons from 'react-icons-ng/all'
import { content } from "src/utils/functions/core"
const queryFetch = require("../functions/queryFetch");
const formater = require("../functions/formater");

const allinOne = async (docType, type) => {
  // let dataTable = []
  const get = await setAllinOne(docType, type)
  return get
}

const setAllinOne = (docType, type) => {
  const { auth_token } = content()
  if (docType && type && auth_token) {
    return new Promise((resolve, reject) => {
      queryFetch.querySelectCustom({
        url: "/select_querySelectGet",
        token: auth_token,
        data: { docType, type }
      }).then(res => {
        if (res) {
          resolve(res)
        } else {
          resolve(false)
        }
      })
    });
  } else {
    return false
  }
}

const allinPut = async (docType, type) => {
  const get = await setAllinPut(docType, type)
  return get
}

const setAllinPut = (docType, type) => {
  const { auth_token } = content()
  if (docType && type && auth_token) {
    return new Promise((resolve, reject) => {
      queryFetch.querySelectPut({
        url: "/select_querySelectPut",
        token: auth_token,
        data: { docType, type }
      }).then(res => {
        if (res) {
          resolve(res)
        } else {
          resolve(false)
        }
      })
    });
  } else {
    return false
  }
}

const allinPost = async (docType, type) => {
  const get = await setAllinPost(docType, type)
  return get
}

const setAllinPost = (docType, type) => {
  const { auth_token } = content()
  if (docType && type && auth_token) {
    return new Promise((resolve, reject) => {
      queryFetch.querySelectPost({
        url: "/select_querySelectPost",
        token: auth_token,
        data: { docType, type }
      }).then(res => {
        if (res) {
          resolve(res)
        } else {
          resolve(false)
        }
      })
    });
  } else {
    return false
  }
}

const catalog = async (type, authToken, e, keys) => {
  const get = await setData(type, authToken, e)
  return get
}

const setData = (type, authToken, e, keys) => {
  return new Promise((resolve, reject) => {
    queryFetch.querySelect({
      url: "/select_catalog",
      token: authToken,
      type: type,
      keys: keys ? keys : "i4c.*",
    }).then(res => {
      if (res && res.length > 0) {// eslint-disable-next-line
        resolve({ [type]: res.map((el, i) => ({ ...el, value: el._id, label: eval(e) })) })
      } else {
        reject(false)
      }
    })
  });
}

const ItemCatalog = async (type, authToken, keys, id) => {
  const get = await setDataItemCatalog(type, authToken, keys, id)
  return get
}

const setDataItemCatalog = (type, authToken, keys, id) => {
  return new Promise((resolve, reject) => {
    queryFetch.querySelect({
      url: "/select_item_catalog",
      token: authToken,
      type: type,
      keys: keys,
      id: id
    }).then(res => {
      // console.log(res)
      if (res) {// eslint-disable-next-line
        resolve(res)
      } else {
        reject(false)
      }
    })
  });
}

const dataCatalog = async (type, authToken, keys) => {
  const get = await setDataCatalog(type, authToken, keys)
  return get
}

const setDataCatalog = (type, authToken, keys) => {
  return new Promise((resolve, reject) => {
    queryFetch.querySelect({
      url: "/select_catalog",
      token: authToken,
      type: type,
      keys: keys ? keys : "i4c.*",
    }).then(res => {
      if (res && res.length > 0) {
        resolve(res)
      } else {
        resolve([])
      }
    })
  });
}

const EventType = (authToken) => {
  return queryFetch.querySelect({
    url: "/select_catalog",
    token: authToken,
    type: "EventType",
    keys: "i4c.*",
  }).then(res => {
    if (res && res.length > 0) {
      return { EventType: res.map((el, i) => ({ value: el._id, label: el.parameters.eventType_name })) }
    } else {
      return false
    }
  })
}

const EventCatalog = (type, authToken, value, label) => {
  return queryFetch.querySelect({
    url: "/select_catalog",
    token: authToken,
    type: "EventCatalog",
    keys: "i4c.*",
  }).then(res => {
    if (res && res.length > 0) {// eslint-disable-next-line
      return { [type]: res.map((el, i) => ({ key: i, value: eval(value), label: eval(label) })) }
    } else {
      return false
    }
  })
}

const customFilter = (url, type, authToken, keys, value, label, props) => {
  return queryFetch.querySelect({
    url: url,
    token: authToken,
    type: type,
    keys: "i4c.*",
  }).then(res => {
    if (res && res.length > 0) {
      // console.log(res)
      return {
        // eslint-disable-next-line
        [type]: res.map((el, i) => ({ key: i, value: eval(value), label: eval(label) })),
        props: props
      }
    } else {
      return false
    }
  })
}

const custom = (type, place, authToken, value, label, props) => {
  return queryFetch.querySelect({
    url: "/select_catalog",
    token: authToken,
    type: type,
    keys: "i4c.*",
  }).then(res => {
    if (res && res.length > 0) {
      return {
        // eslint-disable-next-line
        [place]: res.map((el, i) => ({ key: i, value: eval(value), label: eval(label) })),
        props: props
      }
    } else {
      return false
    }
  })
}

let filterArr = []
const arrFilter = (filter, dataTable, org) => {
  // console.log(filter, dataTable, org)
  // console.log({ a: filter })
  if (!filterArr.find(v => v.type === filter.type)) {
    filterArr.push(filter)
  } else {
    filterArr.forEach((el, i) => {
      if (filterArr.find(v => v.type === filter.type)) {
        if (filterArr.find(v => v.type === filter.type && filter.value)) {
          const removeIndex = filterArr.map(v => v.type).indexOf(filter.type);
          filterArr.splice(removeIndex, 1);
          filterArr.push(filter)
          // console.log({ b: filter })
        } else {
          const removeIndex = filterArr.map(v => v.type).indexOf(filter.type);
          filterArr.splice(removeIndex, 1);
          // console.log({ c: filter })
        }
      } else {
        // console.log({ d: filter })
        filterArr.push(filter)
      }
    })
  }
  let temp = org
  if (filterArr && filterArr.length > 0) {
    filterArr.forEach((f, i) => {
      temp = temp.filter((el, i) => el[f.type] === f.value)
    });
    return temp
  } else {
    return org
  }
}

const getFavorites = (userConfig, x) => {
  // console.log(userConfig)
  let favorites = []
  userConfig.favoriteAssets.forEach((el, i) => {
    favorites.push(el)
  });
  const index = favorites.indexOf(x.value._id);
  if (index > -1) {
    favorites.splice(index, 1)
  } else {
    favorites.push(x.value._id)
  }
  const _userConfig = {
    ...userConfig,
    favoriteAssets: favorites
  }
  // console.log(_userConfig)
  return _userConfig
}

const favoritesLocEdit = (userConfig, x) => {
  // console.log(userConfig)
  let favorites = []
  userConfig.favoriteLocations.forEach((el, i) => {
    favorites.push(el)
  });
  const index = favorites.indexOf(x.value._id);
  if (index > -1) {
    favorites.splice(index, 1)
  } else {
    favorites.push(x.value._id)
  }
  const _userConfig = {
    ...userConfig,
    favoriteLocations: favorites
  }
  // console.log(_userConfig)
  return _userConfig
}

const getData = (type, authToken, user, _userConfig, indexKey, data) => {
  return new Promise((resolve, reject) => {

    // console.log(_user.userConfig)
    queryFetch.queryPut({
      url: "/updateUser",
      token: authToken,
      type: "User",
      id: user.userId,
      // ids: favorites,
      userConfig: _userConfig
    }).then(res => {
      if (res) {
        setTimeout(() => {
          resolve({
            value: data,
            indexKey: !indexKey,
            type: "favoritesEdit",
            succes: res
          })
        }, 2000)
      } else { reject(false) }
    })
  });
}

// let _indexKey
const result = (x, data, indexKey, user, authToken, userConfig) => {
  // console.log(x)
  if (x && x.type === "favorites") {
    // _indexKey = indexKey + 1
    // console.log(data.filter((el) => el.favorites))
    return {
      value: indexKey ? data.filter((el) => el.favorites) : data,
      // value: indexKey ? data.filter((el) => user.userConfig.favoriteAssets.some((el1) => el1 === el._id)) : data,
      indexKey: !indexKey,
      // indKey: _indexKey,
      type: "favorites",
      bypass: true
    }

  } else if (x && x.type === "Input") {
    const input = {
      value: x.value.target.value.length === 0 ? data : data.filter(el => el.asset_name.toLowerCase().includes(x.value.target.value.toLowerCase())),
      indexKey: (!x.value.cancelable && x.value.target.value.length === 0) ? Math.random() : (!x.value.cancelable ? !indexKey : indexKey),
      // indKey: "2",
      type: "Input"
    }
    return input
  } else if (x && x.type === "TreeData") {
    if (x.value === "initialState") {
      return { value: data, indexKey: !indexKey }
    } else {
      const input = {
        value: indexKey !== undefined ? data.filter((el) => x.value.some((el1) => el1._id === el._id)) : data,
        indexKey: !indexKey,
        // indKey: "3",
        type: "TreeData"
      }
      return input
    }
  } else if (x && x.type === "Status") {

    if (isNaN(x.value)) {
      if (!x.value) {
        const input = {
          value: data,
          indexKey: !indexKey,
          // indKey: "4",
          type: "Status"
        }
        return input
      }
    } else {
      const input = {
        value: data.filter(el => el.param.asset_connectionStatus === x.value),
        indexKey: !indexKey,
        // indKey: "4",
        type: "Status"
      }
      return input
    }
  } else if (x && (x.type && x.type.title) === "Table") {
    // console.log(x)
    const input = {
      url: x.type.value,
      indexKey: !indexKey,
      type: "Table"
    }
    return input
  } else if (x && (x.type && x.type.title) === "Grid") {
    // console.log(x.type.va3lue)
    const input = {
      url: x.type.value,
      indexKey: !indexKey,
      type: "Table"
    }
    return input
  } else if (x && x.type === "favoritesEdit") {
    const _userConfig = getFavorites(userConfig, x)
    return getData("User", authToken, user, _userConfig, indexKey, data)
  } else if (x && x.type === "favoritesLocEdit") {
    const _userConfig = favoritesLocEdit(userConfig, x)
    return getData("User", authToken, user, _userConfig, indexKey, data)
  } else if (x && x.type === "AssetType") {
    const input = {
      value: x.value ? data.filter(el => el.param.asset_typeName.toLowerCase().includes(x.value.toLowerCase())) : data,
      indexKey: x.value ? !indexKey : indexKey,
      // indKey: "2",
      type: "AssetType"
    }
    return input
  } else if (x && x.type === "AssetModel") {
    // console.log(data)
    const input = {
      value: x.value ? data.filter(el => el.param.asset_modelName.toLowerCase().includes(x.value.toLowerCase())) : data,
      indexKey: x.value ? !indexKey : indexKey,
      // indKey: "2",
      type: "AssetModel"
    }
    return input
  } else if (x && (x.type && x.type.title) === "InputGeneric") {
    // console.log(x.value.target.value)
    // console.log(x.type.keys)
    // const key = x.type.keys ? eval(x.type.keys) : ""
    // console.log(data)
    // data.map((el) => console.log(el[x.type.keys]))
    const input = {}
    return input
  } else if (x && (x.type && x.type.title) === "InputUser") {
    console.log(x.value.cancelable)
    const input = {
      value: (x.value && x.value.target.value.length > 0)
        ? data.filter(el => el.parameters.firstName.toLowerCase().includes(x.value.target.value.toLowerCase()) || el.parameters.lastName.toLowerCase().includes(x.value.target.value.toLowerCase()))
        : data,
      indexKey: (!x.value.cancelable && x.value.target.value.length === 0) ? Math.random() : (!x.value.cancelable ? !indexKey : indexKey),
      // indKey: "2",
      // type: "AssetModel"
    }
    return input
  }
}

const getUserTrack = async (id, type, authToken, keys) => {
  const get = await setUserTrack(id, type, authToken, keys)
  return get
}

const setUserTrack = (id, type, authToken, keys) => {
  // console.log({id, type, authToken, keys})
  return new Promise((resolve, reject) => {
    return queryFetch.querySelect({
      url: "/select_custom_catalog",
      token: authToken,
      type: type,
      keys: keys ? keys : "i4c.*",
      extraQuery: 'AND _userId = "' + id + '" ORDER BY _changeTimestamp DESC LIMIT 1'
    }).then(res => {
      if (res && res.length > 0) {
        // console.log(res[0])
        resolve(res[0])
      } else {
        reject(false)
      }
    })
  });
}

const removeDuplicateObj = (array, property) => {
  const uniqueIds = [];
  if (array && array.length > 0) {
    const unique = array.filter(el => {
      const isDuplicate = uniqueIds.includes(el[property]);
      if (!isDuplicate) {
        uniqueIds.push(el[property]);
        return true
      }
      return false
    })
    return unique;
  } else {
    return false
  }
}

const removeDuplicate = (arr) => {
  return [...new Set(arr.map((s) => JSON.stringify(s)))].map((s) => JSON.parse(s));
}



// const openNotification = () => {
//   hide = message.info("Action in progress..", 0);
// };
let hideNotification = () => {
  return ""

};

const renderIconV2 = (icon, size, color) => {
  // const Component = icons[icons[icon] ? icon : "MdOutlineDisabledByDefault"]
  // return <Component style={{ color: color, fontSize: size }} />
  return <span>o</span>
}

const renderIcon = (icon, size, color) => {
  // const Component = icons[icon]
  return <span>o</span>
  // return <Component style={{ color: color, fontSize: size }} />
}

const openNotification = (res, context) => {
  const { lang, renderIcon } = context
  const title = () => { return renderIcon("AiFillWarning", 20, "rgb(255, 199, 0)", "") }
  const text = () => { return <span>{lang["onPause"] ? lang["onPause"] : "onPause"}</span> }
  hideNotification = text
  // console.log(hideNotification)
  // hideNotification = notification.open({
  //   message: title(),
  //   description: text(),
  //   // content: text(),
  //   placement: "top",
  //   type: 'warning',
  //   duration: 0,
  //   maxCount: 1,
  // }, 0)

  // // const { lang, renderIcon } = this.context.state
  // if (res) {
  // }else {}
}

const calculateSum = (array, property) => {
  const total = array.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);
  return total;
}

const checkPromisStatus = (e) => {
  if (e.status === "fulfilled")
    return e.value
  else return false
}

const formatValue = (e) => {
  switch (typeof e) {
    case 'string': return e;
    case 'number': return checkNumber(e);
    case 'object': return checkObject(e);
    case 'boolean': return "boolean";
    default:

  }
  return "value"
}

const undefObjHandler = (object, _obj) => {
  if (typeof object === 'object') {
    if (JSON.stringify(object).indexOf(_obj) > -1) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const checkObject = (e) => {
  if (e) {
    const findObj = e.label ? e.label : false
    if (findObj) {
      // console.log(e.label)
      return e.label
    } else {
      return "object/array"
    }
  }
}

const checkNumber = (e) => {
  if (new Date(e).getFullYear() === 1970) {
    if (e === 0 || e === 1) {
      return e
    } else {
      return formater.dateTimeFormatter("duration", e, "dd:hh:mm:ss")
    }
    // return e
  } else {
    return dateTimeFormat(e)
  }
}

const filterObjArr = (lang, item, type, _showKeys) => {
  // console.log(item, type, _showKeys)
  const showKeys = _showKeys ? _showKeys : []
  const keys = Object.keys(item)
  const res = keys.map((el, i) => ({ label: el, value: formatValue(item[el]) }))
  const filter = keys.map((el, i) => showKeys.find((f) => f === el) && ({ label: lang[el] ? lang[el] : el, value: formatValue(item[el]) }))
  return filter.filter((el) => el && (el))
}

const dateTimeFormat = (x) => {
  var d = new Date(x);
  var day = String(d.getDate()).padStart(2, '0');
  var month = String(d.getMonth() + 1).padStart(2, '0');
  var year = d.getFullYear();
  var s = d.getSeconds();
  var m = d.getMinutes();
  var h = d.getHours();
  // if (year === 1970) {
  //   console.log(formater.dateTimeFormatter("duration", x, "dd:hh:mm:ss"))
  //   return formater.dateTimeFormatter("duration", x, "dd:hh:mm:ss")
  // }else {
  // }
  return (day + '.' + month + '.' + year + ' - ' + h + ':' + m + ':' + s)
}

const checkMobile = () => {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) {
    return true
  } else {
    return false
  }
}

const limitText = (string, limit) => {
  var dots = "...";
  if (string.length > limit) {
    string = string.substring(0, limit) + dots;
  }
  return string;
}

const emptySpace = (string) => {
  if (string.match(/\s/g)) {
    return true
  }
  return false
}

// "success", "error", "info", "warning", "loading"
const msg = (type, msg, duration) => {
  message.open({
    type: type,
    content: msg,
    duration,
    style: {
      fontSize: 25,
    },
  });
};

const roundDate10Min = (num) => {
  let ms = 1000 * 60 * 10;
  const round123 = new Date(Number(num))
  const roundedDate = Date.parse(new Date(Math.floor(round123.getTime() / ms) * ms));
  return roundedDate
}

const roundDate1Hour = (num) => {
  let ms = 1000 * 60 * 60;
  const round = new Date(Number(num))
  const roundedDate = Date.parse(new Date(Math.floor(round.getTime() / ms) * ms));
  return roundedDate
}

const sortNumAlpObj = (a, b, type) => {
  if (a && b && type) {
    var textA = a?.[type]?.toString().toUpperCase();
    var textB = b?.[type]?.toString().toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }
}

const sortNumAlp = (a, b, type) => {
  if (a && b && type) {
    var textA = a?.[type];
    var textB = b?.[type];
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }
}

const sortNumAlpObjNum = (a, b, type) => {
  if (a && b && type) {
    var numA = a?.[type];
    var numB = b?.[type];
    return numA - numB;
  }
}

const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const lengthCase = (param, f) => {
  const e = param?.length
  try {
    switch (e) {
      case 1: return f[param[0]];
      case 2: return f[param[0]][param[1]];
      case 3: return f[param[0]][param[1]][param[2]];
      case 4: return f[param[0]][param[1]][param[2]][param[3]];
      case 5: return f[param[0]][param[1]][param[2]][param[3]][param[4]];
      case 6: return f[param[0]][param[1]][param[2]][param[3]][param[4]][param[5]];
      default: return "";
    }
  } catch (error) {
    console.log(error)
    return ""
  }
}

const sortingCustom = (data, extra, order) => {
  let arr = []
  order.forEach(item => {
    const filtered = [...data].filter(el => lengthCase(extra, el) === item)
    arr.push(...filtered)
  })
  return arr
}

const openMessage = (b, confirm, msg) => {
  const duration = (b?.duration * 1000)
  const key = 'updatable'
  message.open({
    key,
    type: 'loading',
    content: msg ? msg : 'Please wait saving...',
  });

  setTimeout(() => {
    message.open({
      key,
      type: 'success',
      content: 'saved',
      duration: (duration / 1000),
    });
    confirm(true)
  }, duration);
};

const openMessageV2 = (b, confirm, msg) => {
  const duration = (b?.duration * 1000)
  const before = b?.before ? b?.before : {
    type: 'loading',
    content: msg ? msg : 'Please wait saving...'
  }
  const after = b?.after ? b?.after : {
    type: 'success',
    content: 'saved',
  }
  const key = 'updatable'
  message.open({
    key,
    ...before
  });

  setTimeout(() => {
    message.open({
      key,
      ...after,
      duration: (duration / 1000),
    });
    confirm(true)
  }, duration);
};

const destructUrl = (url) => {
  return Object.fromEntries(new URLSearchParams(url))
}

const urlString = (obj) => {
  const c = new URLSearchParams([
    ...Object.entries(obj),
  ]).toString();
  const _c = c.replaceAll("%2C", ",")
  return _c

}

const setFilters = (e, filter) => {
  let _filter = filter
  if (_filter.find(v => v.type === e.type)) {
    if (_filter.find(v => v.type === e.type) && e.value) {
      const removeIndex = _filter.map(v => v.type).indexOf(e.type);
      _filter.splice(removeIndex, 1);
      _filter.push(e)
    } else {
      const removeIndex = _filter.map(v => v.type).indexOf(e.type);
      _filter.splice(removeIndex, 1);
    }
  } else {
    _filter.push(e)
  }
  return _filter
}

const setFiltersCasdParam = (_filter, dataTable, _dataTable) => {
  if (_filter.length > 0) {
    let tempDataTable = _dataTable
    _filter.forEach((el, i) => {
      tempDataTable = tempDataTable.filter((f, i) => {
        switch (el?.operator) {
          case "<": return el.extra ? f.param[el.type][el.extra] > el.value.value : f.param[el.type] > el.value.value;
          case "<=": return el.extra ? f.param[el.type][el.extra] >= el.value.value : f.param[el.type] >= el.value.value;
          case ">": return el.extra ? f.param[el.type][el.extra] < el.value.value : f.param[el.type] < el.value.value;
          case ">=": return el.extra ? f.param[el.type][el.extra] <= el.value.value : f.param[el.type] <= el.value.value;
          default: return el.extra ? f.param[el.type][el.extra] === el.value.value : f.param[el.type] === el.value.value;
        }
        // const data = el.extra ? f.param[el.type][el.extra] === el.value.value : f.param[el.type] === el.value.value
        // return data
      })
    });
    return tempDataTable
  } else {
    return _dataTable
  }
}

const setFiltersCasd = (_filter, dataTable, _dataTable) => {
  // console.log(_filter)
  if (_filter && _filter.length > 0) {
    let tempDataTable = _dataTable
    _filter.forEach((el, i) => {
      tempDataTable = tempDataTable?.filter((f, i) => {
        switch (el?.operator) {
          case "<": return el.extra ? f[el.type][el.extra] > el.value.value : f[el.type] > el.value.value;
          case "<=": return el.extra ? f[el.type][el.extra] >= el.value.value : f[el.type] >= el.value.value;
          case ">": return el.extra ? f[el.type][el.extra] < el.value.value : f[el.type] < el.value.value;
          case ">=": return el.extra ? f[el.type][el.extra] <= el.value.value : f[el.type] <= el.value.value;
          default: return el.extra ? f[el.type][el.extra] === el.value.value : f[el.type] === el.value.value;
        }
        // const data = el.extra ? f[el.type][el.extra] === el.value.value : f[el.type] === el.value.value
        // return data
      })
    });
    return tempDataTable
  } else {
    return _dataTable
  }
}

const setFiltersCasdCustom = (_filter, dataTable, _dataTable) => {
  // console.log({
  //   _filter, dataTable, _dataTable
  // })
  if (_filter.length > 0) {
    let tempDataTable = _dataTable
    _filter.filter((el, i) => {
      // console.log(el)
      tempDataTable = tempDataTable.filter((f) => f?.[el?.type]?.toLowerCase().includes(el?.value?.value?.toLowerCase()))
      // if (check) {
      //   tempDataTable.push(check)
      // }
    });
    if (_filter.length > 1) {

      _filter.forEach((el, i) => {
        const check = _dataTable.find((f) => f?.[el?.type]?.toLowerCase().includes(el?.value?.value?.toLowerCase()))
        if (check) {
          tempDataTable.push(check)
        }
      });
    }
    return tempDataTable
  } else {
    return _dataTable
  }
}

const setFiltersCasdCustomV2 = async (_filter, dataTable, _dataTable) => {
  if (_filter.length > 0) {
    let tempDataTable = []
    await _filter.forEach(async (el, i) => {
      await _dataTable.forEach((f, i) => {
        // console.log({
        //   type: f?.[el?.type],
        //   value: el.value.value?.toLowerCase()
        // })
        if (f?.[el?.type]) {
          if (f?.[el?.type].toLowerCase().includes(el?.value?.value?.toLowerCase())) {
            tempDataTable.push(f)
          }
        }
      });
    });
    return tempDataTable
  } else {
    return _dataTable
  }
}

const sliceText = (e, size) => {
  return e?.slice(-size)
}
// const setFiltersCasdCustom = async(_filter, dataTable, _dataTable) => {
//   // console.log({
//   //   _filter, dataTable, _dataTable
//   // })
//   if (_filter.length > 0) {
//     let tempDataTable = []
//     _dataTable.forEach((el, i) => {
//       const check = _filter.filter((f) => el?.[f.type]?.toLowerCase().includes(f.value.value?.toLowerCase()))
//       if (check.length > 0) {
//         tempDataTable.push(el)
//       }
//     })
//
//     // const d = _dataTable.map((el, i) => {
//     //   const check = _filter.filter((f) => el?.[f.type]?.toLowerCase().includes(f.value.value?.toLowerCase()))
//     //   if (check.length > 0) {
//     //     return el
//     //   }
//     // })
//     return tempDataTable
//   } else {
//     return _dataTable
//   }
// }

const shiftDefine = (_shiftDuration) => {
  const { layout } = content()
  // const shift = layout?.shift
  const shiftDuration = _shiftDuration ? _shiftDuration : 8
  const now = new Date();
  let hour
  let hours
  let shiftEnd
  const morningShiftStart = 6;
  const morningShiftEnd = 14;
  const afternoonShiftStart = 14;
  const afternoonShiftEnd = 22;
  const eveningShiftStart = 22;
  const eveningShiftEnd = 6;

  const currentDate = new Date();
  const currentHour = now.getHours();

  let shift;
  if (currentHour >= morningShiftStart && currentHour < morningShiftEnd) {
    hour = morningShiftStart
    shift = "Morning";
    shiftEnd = morningShiftStart + shiftDuration
  } else if (currentHour >= afternoonShiftStart && currentHour < afternoonShiftEnd) {
    hour = afternoonShiftStart
    shift = "Afternoon";
    shiftEnd = afternoonShiftStart + shiftDuration
  } else {
    hour = eveningShiftStart
    shift = "Evening";
    shiftEnd = eveningShiftStart + shiftDuration
  }
  hours = currentHour - hour
  // console.log({
  //   currentHour,
  //   shift,
  //   hour,
  //   timestamp: currentDate.setHours(hour, 0, 0, 0),
  //   hours
  // })
  return {
    start: currentDate.setHours(hour, 0, 0, 0),
    startFormat: hour,
    end: currentDate.setHours((hour + hours + 1), 0, 0, 0),
    endFormat: (hour + hours),
    hours,
    shiftEnd: currentDate.setHours(shiftEnd, 0, 0, 0),
  }
}
const iconHandler = (status) => {
  const { layout } = content()
  const size = layout?.statusIcon?.size ?? 16
  const color = layout?.statusIcon?.colors ?? ["#ff0000", "#009933", "#ff0000"]
  switch (status) {
    case "undefined": return renderIconV2("BsFillCircleFill", size, color[0]);
    case null: return renderIconV2("BsFillCircleFill", size, color[0]);
    case 0: return renderIconV2("BsFillCircleFill", size, color[0]);
    case 1: return renderIconV2("BsFillCircleFill", size, color[1]);
    case 2: return renderIconV2("FlIcFluentPlugDisconnected24Regular", (size + 4), color[2]);
    default:
  }
}
const statusHandler = (status, lang) => {
  const { layout } = content()
  const size = layout?.statusIcon?.size ?? 16
  const color = layout?.statusIcon?.colors ?? ["#ff0000", "#009933", "#ff0000"]
  switch (status) {
    // case "undefined": return { label: (lang?.["stopped"] ?? "stopped"), color: "#ff0000" };
    // case null: return { label: (lang?.["stopped"] ?? "stopped"), color: "#ff0000" };
    // case 0: return { label: (lang?.["stopped"] ?? "stopped"), color: "#ff0000" };
    case 1: return { label: (lang?.["InProduction"] ?? "InProduction"), color: "#009933" };
    case 2: return { label: (lang?.["off"] ?? "off"), color: "#ffca2c" };
    default: return { label: (lang?.["off"] ?? "off"), color: "#ff0000" };
  }
}

const sortingAsc = (data, extra) => {
  let copyData = [...data]
  let arr = copyData.sort((a, b) => {
    var textA = lengthCase(extra, a);
    var textB = lengthCase(extra, b);
    let textAC = typeof textA === "number" ? textA : (typeof textA === "boolean" ? (textA ? 0 : 1) : (textA === null ? 0 : textA.toLowerCase()))
    let textBC = typeof textB === "number" ? textB : (typeof textB === "boolean" ? (textB ? 0 : 1) : (textB === null ? 0 : textB.toLowerCase()))
    return (textAC < textBC) ? -1 : (textAC > textBC) ? 1 : 0;
  })
  return arr
}

const sortingDesc = (data, extra) => {
  let copyData = [...data]
  let arr = copyData.sort((a, b) => {
    var textA = lengthCase(extra, b);
    var textB = lengthCase(extra, a);
    let textAC = typeof textA === "number" ? textA : (typeof textA === "boolean" ? (textA ? 0 : 1) : (textA === null ? 0 : textA.toLowerCase()))
    let textBC = typeof textB === "number" ? textB : (typeof textB === "boolean" ? (textB ? 0 : 1) : (textB === null ? 0 : textB.toLowerCase()))
    return (textAC < textBC) ? -1 : (textAC > textBC) ? 1 : 0;
  })
  return arr
}



export {
  catalog,
  EventType,
  EventCatalog,
  custom,
  arrFilter,
  result,
  customFilter,
  getUserTrack,
  removeDuplicateObj,
  removeDuplicate,
  ItemCatalog,
  openNotification,
  hideNotification,
  renderIcon,
  renderIconV2,
  calculateSum,
  dataCatalog,
  checkPromisStatus,
  formatValue,
  checkMobile,
  allinOne,
  allinPut,
  allinPost,
  undefObjHandler,
  filterObjArr,
  limitText,
  emptySpace,
  msg,
  roundDate10Min,
  roundDate1Hour,
  sortNumAlpObj,
  randomNumber,
  sortNumAlpObjNum,
  sortingCustom,
  openMessage,
  openMessageV2,
  destructUrl,
  urlString,
  setFilters,
  setFiltersCasdParam,
  setFiltersCasd,
  setFiltersCasdCustom,
  setFiltersCasdCustomV2,
  sliceText,
  shiftDefine,
  iconHandler,
  statusHandler,
  sortingAsc,
  sortingDesc,
  sortNumAlp
}
