/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
// import { Card } from 'react-bootstrap';
import { Divider, Table, Button, Select, Form } from 'antd';
import { MainContext } from 'src/Context';
import { allinOne, allinPut, msg, sortNumAlpObj, removeDuplicateObj, sortingAsc } from "src/utils/functions/filterFunction"
import { updateSpecSelectedOperations } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"
import { TitleInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { content } from "src/utils/functions/core"
import { dateTime } from "src/utils/functions/formater"
import { socket } from 'src/utils/socket';
import Spinner from "src/layouts/components/spinner"
import { pagination } from "src/utils/functions/specStyle"

const App = (props) => {
  const mounted = useRef(true);
  let context = useContext(MainContext);
  if (props?.context) {
    context = props?.context
  }
  const { lang, assetId } = context.state
  const { user } = content()
  const { style } = context
  const [item, setItem] = useState([])
  const [operation, setOperation] = useState([])
  const [children, setChildren] = useState(<Spinner />)
  // const [childrenV2, setChildrenV2] = useState(<Spinner />)
  // console.log(props)
  const [options, setOptions] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [board, setBoard] = useState(props.board)
  const [users, setUsers] = useState([])
  const id = props?.item?._id
  const _kpi = props.board.map((el) => (el?.kpi))

  const getData = async () => {
    if (mounted.current) {
      const result = await allinOne("getSpecDocument", {})
      if (result) {
        const _asset = result?.assets?.[assetId]
        if (_asset) {
          const asset = _asset.map((el, i) => ({ ...el, key: i, label: el?.labelUser, value: el?.userId }))
          // console.log(asset)
          return asset
        } else {
          msg("warning", (lang?.["noUserDefined"] ?? "noUserDefined"), 2)
        }
      }
    }
  }

  const getOp = async () => {
    if (mounted.current) {
      const id = props?.item?._id
      // console.log(id)
      const result = await allinOne("getDocument", { type: "Operation", id })
      // console.log(result?.r?.param.workers)
      // console.log(result)
      if (result.success) {
        return result?.r
      }
    }
  }

  const _setDataTable = async (e) => {
    setDataTable(e)
    socket.emit("operationBoardRefresh")
    socket.emit("usersOnV2", true)
    // console.log({
    //   id,
    //   e
    // })
    // alert("Not saved, coming in next release")
    const result = allinPut("updateUserToOp", { id, workers: e })
  }

  // console.log(props)
  const handleStartStop = async (e, s) => {
    // const now = Date.now()
    // const _dataTable = dataTable.map((el, i) => ({ ...el, key: i + 1 }))
    // const row = _dataTable.find((f) => f.userId === e)
    // const filtered = _dataTable.filter((f) => f.userId !== e)
    // row.endDate = now
    // const newDataTable = [...filtered, row].sort((a, b) => a.key - b.key)

    const result = await allinPut("updateUserToOperation", {
      operationId: id,
      userId: e,
      // userId: user?.userId,
      assetId,
      active: s,
      status: operation?.status,
      wofId: props?.children?.wofId
    })
    if (result.success) {
      setDataTable(result?.updatedOperation?.param?.workers)
      // console.log({
      //   result,
      //   // newDataTable
      // })
    }
  }

  const handleStop = async (e) => {
    handleStartStop(e, 0)
  }

  const handleStart = async (e) => {
    handleStartStop(e, 1)
  }

  const f = async () => {
    const c = await import("./CoworkersTable")
    const kpi = await removeDuplicateObj(_kpi, "value")
    const CoworkersTable = c?.default
    // const cv = await import("./CoworkersTableV2")
    // const CoworkersTableV2 = cv?.default
    const now = Date.now()
    const remapDataTable = dataTable.map((el, i) => {
      // const waitTimeEndDate = el?.operationTimes?.waitTime?.endDate
      // const waitTimeTimeDuration = waitTimeEndDate ? ((now - waitTimeEndDate) / 1000) : (el?.operationKpis?.waitTime?.duration ? el?.operationKpis?.waitTime?.duration : 0)
      // el.operationKpis.waitTime.duration = waitTimeTimeDuration


      if (el?.operationKpis?.waitTime?.duration === 0) {
        const plannedEndDate = el?.operationTimes?.Planned?.endDate //? el?.operationTimes?.Planned?.endDate : el?.operationTimes?.Planned?.startDate
        // const setupTimeDuration = plannedEndDate ? ((now - plannedEndDate) / 1000) : (el?.operationKpis?.setupTime?.duration ? el?.operationKpis?.setupTime?.duration : 0)
        const setupTimeDuration = plannedEndDate ? (el?.operationKpis?.waitTime?.duration ? el?.operationKpis?.waitTime?.duration : 0) : ((el?.operationTimes?.Preparation?.startDate ? ((now - el?.operationTimes?.Preparation?.startDate) / 1000) : 0) + (el?.operationKpis?.waitTime?.duration ? el?.operationKpis?.waitTime?.duration : 0))
        // console.log(setupTimeDuration)
        el.operationKpis.waitTime.duration = setupTimeDuration
      }
      if (el?.operationKpis?.setupTime?.duration === 0) {
        const plannedEndDate = el?.operationTimes?.Planned?.endDate //? el?.operationTimes?.Planned?.endDate : el?.operationTimes?.Planned?.startDate
        // const setupTimeDuration = plannedEndDate ? ((now - plannedEndDate) / 1000) : (el?.operationKpis?.setupTime?.duration ? el?.operationKpis?.setupTime?.duration : 0)
        const setupTimeDuration = plannedEndDate ? (el?.operationKpis?.setupTime?.duration ? el?.operationKpis?.setupTime?.duration : 0) : ((el?.operationTimes?.Planned?.startDate ? ((now - el?.operationTimes?.Planned?.startDate) / 1000) : 0) + (el?.operationKpis?.setupTime?.duration ? el?.operationKpis?.setupTime?.duration : 0))
        // console.log(setupTimeDuration, plannedEndDate)
        el.operationKpis.setupTime.duration = setupTimeDuration
      }

      if (el?.operationKpis?.productionTime?.duration === 0) {
        const preparationEndDate = el?.operationTimes?.InProduction?.endDate // ? el?.operationTimes?.InProduction?.endDate : el?.operationTimes?.InProduction?.startDate
        const preparationTimeDuration = preparationEndDate ? (el?.operationKpis?.productionTime?.duration ? el?.operationKpis?.productionTime?.duration : 0) : ((el?.operationTimes?.InProduction?.startDate ? ((now - el?.operationTimes?.InProduction?.startDate) / 1000) : 0) + (el?.operationKpis?.productionTime?.duration ? el?.operationKpis?.productionTime?.duration : 0))
        // console.log(preparationTimeDuration, preparationEndDate)
        el.operationKpis.productionTime.duration = preparationTimeDuration
      }
      return ({
        ...el,
        key: i + 1
      })
    })
    const tableProps = {
      pagination
    }
    setChildren(
      <CoworkersTable
        key={JSON.stringify(remapDataTable)}
        tableProps={tableProps}
        operation={operation}
        kpi={kpi}
        setDataTable={(e) => _setDataTable(e)}
        handleStop={(e) => handleStop(e)}
        handleStart={(e) => handleStart(e)}
        dataTable={remapDataTable}
        _context={context} />
    )
    // setChildrenV2(<CoworkersTableV2 operation={operation}/>)
  }

  const g = async () => {
    const _d = await getData()
    const d = sortingAsc(_d, ["label"])
    const op = await getOp()
    const _dataTable = op?.param?.workers
    // console.log(_dataTable)
    setOperation(op)
    setDataTable(_dataTable)
    setOptions(d)
  }

  useEffect(() => {
    g()
    f()
    return () => {
      mounted.current = false
      // socket.off('usersOn')
    }
  }, [])

  useEffect(() => {
    // console.log(dataTable)
    if (dataTable?.length > 0) {
      f()
    }
  }, [dataTable])

  const addUser = async (e) => {
    const findUser = dataTable.find(f => f?.userId === e?.userId)
    if (findUser?.active !== 1) {
      const status = board.find((f) => f?.id === operation?.param?.status?._id)
      const statusKpi = board.find((f) => f?.id === operation?.param?.status?._id)
      const kpi = await removeDuplicateObj(_kpi, "value")
      const now = Date.now()
      const wh = {
        duration: 0,
        startDate: now,
        endDate: null,
      }
      const _e = {
        ...e,
        productionTime: 0,
        startDate: now,
        active: 1,
        workHistory: [wh],
        operationTimes: {
          [status?.name]: {
            duration: 0,
            endDate: null,
            endDateFormat: null,
            startDate: now,
            startDateFormat: dateTime(now),
            statusType: status?.kpi?.value
          }
        },
        operationKpis: {
          [status?.kpi?.value]: {
            duration: 0
          }
        }
      }
      // console.log(operation?._id)
      const e1 = {
        active: 1,
        assetId: e?.assetId,
        labelAsset: e?.labelAsset,
        labelUser: e?.labelUser,
        erp_userId: e?.erp_userId,
        // timestamp,
        userId: e?.userId
      }
      // console.log(e1)
      const result1 = await allinPut("removeUserOnOperations", e1)
      if (result1) {
        const findU = dataTable.find(f => f?.userId === e?.userId)
        const _dataTable = findU ? [...dataTable] : [...dataTable, _e]
        const __dataTable = _dataTable.map((el) => {
          if (el?.userId === e?.userId) {
            return { ...el, active: 1 }
          }
          else {
            return el
          }
        })
        //const newDataTable = removeDuplicateObj(__dataTable, "userId").map((el, i) => ({ ...el, key: i + 1 }))
        setDataTable(__dataTable)

        // setIsLoading(true)
        // console.log(id)
        const _e1 = {
          assetId: e?.assetId,
          labelAsset: e?.labelAsset,
          userId: e?.userId,
          labelUser: e?.labelUser,
          erp_userId: e?.erp_userId,
          operationIds: [operation?._id]
        }
        await updateSpecSelectedOperations(_e1)
        socket.emit("operationBoardRefresh")
        socket.emit("usersOnV2", true)
        //const result = allinPut("updateUserToOp", { id, workers: newDataTable, kpi })
        // updateSpecOperation(e1)
        //  if (result) {
        // setIsLoading(false)
        //   }
        // setUser(e)
      }
      // console.log({
      //   // statusId: operation?.param?.status?._id,
      //   // status: board.find((f) => f?.id === operation?.param?.status?._id),
      //   // operation,
      //   // props,
      //   _e
      // })
    }
  }


  const title = lang?.[props?.tabName] ?? props?.tabName
  // const _item = { name: state?.operation?.param?.operation?.operationName }
  const _item = { name: props?.item?.param?.operation?.operationName }
  return (
    <div style={style.OperationManipulateMo}>
      {/*
      <TitleInfo key={JSON.stringify(_item)} styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
      <Form>
        <Form.Item label={lang?.["addOperator"] ?? "addOperator"}>
          <Select
            disabled={props?.item?.param?.status?._id === "eea744d7-0c70-4b92-a6a6-587008f19145" || props?.item?.param?.status?._id === "bad71241-1d82-4774-8342-b593087f35cb" ? true : false}
            style={{ width: 200, }}
            options={options}
            onSelect={(e, i) => addUser(i)}
          />
        </Form.Item>
      </Form>
      <Divider />
      <div>
        {children}
      </div>
    </div>
  )
}

export default App;
// {childrenV2}
// <InfoColumns styles={props?.children?.styles?.InfoColumns} key={JSON.stringify(columns)} dataProps={columns} />
